import React from 'react'
import AddToCartButton from '@/public/product/AddToCartButton'
import ApplicationButton from '@/public/product/ApplicationButton'
import Proptypes from 'prop-types'
import NotifyWhenAvailable from './NotifyWhenAvailable'

export default function ProductCardButtons({
  product = null,
  awaitedProductIds = [],
  text,
  textAlt,
  productUrl,
}) {
  return !product ? '' : (
    <>
      {product.is_applicable && (
        <ApplicationButton product={product} product_url={productUrl} />
      )}

      {
        /back_ordered/i.test(product.status) ? (
          <NotifyWhenAvailable
            product={product}
            buttonText="Notify me"
            placeholderText="Email"
            awaitedProductIds={awaitedProductIds}
          />
        ) : (
          <>
            {product.is_purchasable
              && <AddToCartButton product={product} text={text} />}

            {product.is_subscribable
              && (
              <AddToCartButton
                product={product}
                text={textAlt}
                subscription
                className="btn btn-subscription"
              />
              )}
          </>
        )
      }
    </>
  )
}

ProductCardButtons.propTypes = {
  product: Proptypes.shape({
    is_applicable: Proptypes.bool,
    status: Proptypes.string,
  }).isRequired,
  text: Proptypes.string,
  textAlt: Proptypes.string,
  productUrl: Proptypes.string,
}

ProductCardButtons.defaultProps = {
  text: 'Add to cart',
  textAlt: 'Subscribe',
  productUrl: '',
}
