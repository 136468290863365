import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios';
import { accessToken } from '@/shared/helpers/CSRF'
import { selectVerified } from '@/public/redux/recaptchaSlice'
import useCart from '@/public/hooks/use-cart';
import {
  selectLoading,
  setLoading,
  selectPaymentRequestOpen,
} from '@/public/redux/cartSlice'

export default function DiscountCode({ delayToClear = 3000 }) {
  const dispatch = useDispatch()
  const verified = useSelector(selectVerified)
  const loading = useSelector(selectLoading)
  const paymentRequestOpen = useSelector(selectPaymentRequestOpen)
  const { cart, mutate } = useCart()

  const [code, setCode] = useState('')
  const [message, setMessage] = useState('')

  const handleDiscountAttempt = (e) => {
    e.preventDefault()

    if (code === '') {
      return
    }

    dispatch(setLoading(true))

    axios({
      method: 'post',
      url: '/api/cart/discount',
      headers: { 'X-CSRF-Token': accessToken },
      data: { uuid: cart.uuid, code },
    })
      .then(({ data }) => {
        setMessage(data.message)
      })
      .finally(() => {
        mutate()
        dispatch(setLoading(false))
      })
  }

  /**
   * React to a discount check attempt
   *
   * SUCCESS: refresh the cart contents
   * FAIL: auto-clear the input/message after a short delay
   */
  useEffect(() => {
    if (!message) { return }

    window.setTimeout(() => {
      setCode('')
      setMessage('')
    }, delayToClear)
  }, [message])

  const cartCode = cart?.discount_code?.code

  return cartCode ? '' : (
    <div className="cart-discount">
      <div>
        <label htmlFor="discountCode" className="form-label">
          Discount Code
        </label>

        <div className="input-group">
          <input
            id="discountCode"
            className={`form-control text-uppercase fs-6 font-monospace ${(cartCode ? 'disabled' : '')}`}
            type="text"
            value={code}
            disabled={!verified || cartCode || message}
            onChange={(e) => setCode(e.target.value)}
          />

          <button
            className={`btn btn-primary ${(cartCode ? 'disabled' : '')}`}
            disabled={!verified || cartCode || message || paymentRequestOpen}
            onClick={handleDiscountAttempt}
          >
            {
              loading ? 'CHECKING'
                : cartCode
                  ? 'NICE!'
                  : 'CHECK'
            }
          </button>
        </div>

        { message
          && (
          <p
            className={`px-3 mt-0 mb-n4 ${cartCode ? 'text-success' : 'text-danger'}`}
            style={{ fontSize: '88%' }}
          >
            { message }
          </p>
          )}
      </div>
    </div>
  )
}
