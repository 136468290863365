import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios';
import useCart from '@/public/hooks/use-cart';
import { accessToken } from '@/shared/helpers/CSRF'
import { selectVerified } from '@/public/redux/recaptchaSlice'
import { setOpen, setLoading } from '@/public/redux/cartSlice'

export default function AddToCartButton({
  product,
  quantity = 1,
  text = 'Add to Cart',
  className = 'btn btn-primary',
  disabled = false,
  subscription = false,
  children,
}) {
  const verified = useSelector(selectVerified)
  const { cart, mutate } = useCart()
  const dispatch = useDispatch()

  const existingItem = cart?.contents?.find((item) => item.data.sku === product.sku)
  const otherPurchaseTypeSelected = existingItem && existingItem.subscription !== subscription

  const quantityLimited = existingItem && !product.quantifiable

  const shouldDisable = !verified
    || quantityLimited
    || disabled
    || /back_ordered/i.test(product.status)
    || otherPurchaseTypeSelected

  const createCart = () => {
    axios({
      method: 'post',
      url: '/api/cart',
      headers: { 'X-CSRF-Token': accessToken },
      data: {
        attributes: { user_id: null },
        cart: {
          contents: [
            {
              data: product,
              quantity,
              subscription,
            },
          ],
        },
      },
    })
      .then(({ data }) => {
        window.localStorage.setItem('cart', data.uuid)
        mutate()
      })
      .then(() => dispatch(setLoading(false)))
  }

  const addItemToCart = () => {
    axios({
      method: 'post',
      url: `/api/cart/${cart.uuid}/cart_items`,
      headers: { 'X-CSRF-Token': accessToken },
      data: { sku: product.sku, quantity, subscription },
    })
      .then(() => {
        mutate()
      })
      .then(() => dispatch(setLoading(false)))
  }

  const incrementCart = () => {
    axios({
      method: 'patch',
      url: `/api/cart/${cart.uuid}/cart_items/${product.id}`,
      headers: { 'X-CSRF-Token': accessToken },
      data: { quantity: existingItem.quantity + 1 },
    })
      .then(() => {
        mutate()
      })
      .then(() => dispatch(setLoading(false)))
  }

  const handleClick = (e) => {
    e.stopPropagation()

    dispatch(setLoading(true))
    dispatch(setOpen(true))

    if (existingItem) {
      incrementCart()
    } else if (cart) {
      addItemToCart()
    } else {
      createCart()
    }
  }

  return (
    <button
      type="button"
      className={className}
      disabled={shouldDisable}
      onClick={(e) => handleClick(e)}
    >
      { children || text }
    </button>
  )
}
