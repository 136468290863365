import React from 'react'
import useCart from '@/public/hooks/use-cart';
import { numberToCurrency } from '@/shared/helpers/String'
import CartLedgerItem from '@/public/cart/CartLedgerItem'

export default function CartLedger() {
  // TAXES ARE NOT CALCULATED AT THIS TIME
  const taxesEnabled = false

  const { cart } = useCart();
  if (!cart) { return null }

  return (
    <>
      <CartLedgerItem
        label="Subtotal"
        value={numberToCurrency(cart.calculated_total_before_savings)}
      />
      {
      parseFloat(cart.calculated_amount_saved) > 0
        && (
        <CartLedgerItem
          label="Savings"
          value={
            cart.calculated_amount_saved > 0
              ? `-${numberToCurrency(cart.calculated_amount_saved)}`
              : numberToCurrency(0)
          }
        />
        )
    }

      {parseFloat(cart.calculated_service_fee) > 0
      && (
      <CartLedgerItem label="Lab Draw">
        {numberToCurrency(cart.calculated_service_fee)}
      </CartLedgerItem>
      )}

      <CartLedgerItem label="Shipping">
        {numberToCurrency(cart.calculated_shipping_cost)}
      </CartLedgerItem>

      { taxesEnabled
      && (
      <CartLedgerItem
        label="Taxes"
        value=""
      />
      )}

      <CartLedgerItem
        label="Total"
        discountCode={cart.discount_code?.code}
        value={numberToCurrency(cart.calculated_total_after_savings)}
        className="fw-bold mt-2"
      />
    </>
  )
}
