// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '@popperjs/core/dist/umd/popper.min'
import 'bootstrap'
import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import '@lottiefiles/lottie-player'

import 'channels'
import 'scripts'

Rails.start()
ActiveStorage.start()

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true)
const ReactRailsUJS = require('react_ujs')

ReactRailsUJS.useContext(componentRequireContext)

window.rails_timezone = require('rails-timezone')

window.loadStripeCheckout = () => {
  import('../javascript/scripts/stripe-checkout')
}
