import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  debug: false,
  status: 'fresh',
  loaded: false,
  loading: false,
  error: null,
  open: false,
  user_id: null,
  customer: null,
  order: null,
  stripeCustomer: null,
  payment_success: null,
  order_confirmation_id: null,
  shipping_costs: null,
  paymentRequestOpen: false,
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setError: (state, action) => {
      state.error = action.payload
    },

    setOpen: (state, action) => {
      state.open = Boolean(action.payload)
      document.body.classList[action.payload ? 'add' : 'remove']('noscroll')
    },

    setUserId: (state, action) => {
      state.user_id = action.payload
    },

    setPaymentSuccess: (state, action) => {
      state.payment_success = action.payload
    },

    setOrderConfirmationId: (state, action) => {
      state.order_confirmation_id = action.payload
    },

    setCustomer: (state, action) => {
      state.customer = action.payload
    },

    setOrder: (state, action) => {
      state.order = action.payload
    },

    setStripeCustomer: (state, action) => {
      state.stripeCustomer = action.payload
    },

    setShippingCosts: (state, action) => {
      state.shipping_costs = action.payload
    },

    setPaymentRequestOpen: (state, action) => {
      console.log('payment request action:', action)
      state.paymentRequestOpen = action.payload
    },
  },
})

export const {
  setCustomer,
  setOrder,
  setStripeCustomer,
  setCheckoutUrl,
  setOrderConfirmationId,
  setLoading,
  setError,
  setOpen,
  setShippingCosts,
  setPaymentRequestOpen,
  setPaymentSuccess,
  setUserId,
} = cartSlice.actions

export const selectLoaded = (state) => state.cart.loaded
export const selectLoading = (state) => state.cart.loading
export const selectError = (state) => state.cart.error
export const selectStatus = (state) => state.cart.status
export const selectOpen = (state) => state.cart.open
export const selectUserId = (state) => state.cart.user_id
export const selectContents = (state) => state.cart.contents
export const selectCustomer = (state) => state.cart.customer
export const selectOrder = (state) => state.cart.order
export const selectShippingCosts = (state) => state.cart.shipping_costs
export const selectStripeCustomer = (state) => state.cart.stripeCustomer
export const selectPaymentSuccess = (state) => state.cart.payment_success
export const selectOrderConfirmationId = (state) => state.cart.order_confirmation_id
export const selectContainsAMealPlan = (state) => state.cart.contains_a_meal_plan
export const selectPaymentRequestOpen = (state) => state.cart.paymentRequestOpen

export const fetchCustomer = (user_id) => async (dispatch, getState) => {
  axios({
    method: 'get',
    url: `/api/users/${user_id}`,
  })
    .then(({ data: customer }) => {
      dispatch(setCustomer(customer))
    })
}

export default cartSlice.reducer

