import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { accessToken } from '@/shared/helpers/CSRF'
import useCart from '@/public/hooks/use-cart';
import { setOpen, setLoading } from '@/public/redux/cartSlice'
import { selectVerified } from '@/public/redux/recaptchaSlice'
import { numberToCurrency } from '@/shared/helpers/String'
import QuantityInput from '@/public/product/QuantityInput'

export default function CartItem({
  data: item = null, quantity, subscription = false, pickupOrDelivery = null, customerIsMember,
}) {
  const verified = useSelector(selectVerified)

  const customerChoseLocalPickup = /pickup/i.test(pickupOrDelivery)

  const dispatch = useDispatch()
  const { cart, mutate } = useCart()

  const handleRemove = (event) => {
    event.stopPropagation()
    dispatch(setLoading(true))
    axios({
      method: 'delete',
      url: `/api/cart/${cart.uuid}/cart_items/${item.id}`,
      headers: { 'X-CSRF-Token': accessToken },
    })
      .then((response) => {
        if (!response.data) {
          window.localStorage.removeItem('cart')
          dispatch(setOpen(false))
        }
        mutate()
      })
      .then(() => dispatch(setLoading(false)))
  }

  const handleQuantityChange = (newQuantity) => {
    dispatch(setLoading(true))
    axios({
      method: 'patch',
      url: `/api/cart/${cart.uuid}/cart_items/${item.id}`,
      headers: { 'X-CSRF-Token': accessToken },
      data: { quantity: newQuantity },
    })
      .then(() => mutate())
      .then(() => dispatch(setLoading(false)))
  }

  return !item ? '' : (
    <div className="cart-list-item">

      {
        /*
         * BEGIN DESKTOP
         */
      }
      <div className="cart-list-item-desktop d-none d-md-flex w-100">
        <div className="cart-list-item-image">
          <a href={item.permalink}>
            <img src={item.featured_image_url} alt={item.featured_image_alt_text} />
          </a>
        </div>

        <div className="cart-list-item-details">
          <div className="cart-list-item-details-name h4">
            <a href={item.permalink} className="text-decoration-none d-block">
              { item.name }
            </a>
            { subscription
                && <div className="d-inline-block badge bg-secondary mt-2 tiny">Subscription</div>}
          </div>

          <div className="cart-list-item-details-desc">
            <p className="my-0 py-0">{ item.sales_intro }</p>
            <p className="my-0 py-0">{ item.short_description }</p>
          </div>
          {item.quantifiable && <QuantityInput quantity={quantity} handleChange={handleQuantityChange} />}
        </div>

        <div className="cart-list-item-end">
          <div className="cart-list-item-price h5">
            <div>
              {
                  Boolean(item?.original_price) && Number(item.price) !== Number(item.original_price) ? (
                    <>
                      <span>{ numberToCurrency(item.price) }</span>

                      <span className="d-block fw-lighter text-decoration-line-through" style={{ opacity: 0.4, fontSize: '88%' }}>
                        { numberToCurrency(item.original_price) }
                      </span>

                      { subscription && (
                      <>
                        { customerIsMember === false
                        && (
                        <span className="d-block fw-lighter" style={{ opacity: 0.4, fontSize: '65%' }}>
                          -
                          { item.calculated_subscription_savings_percentage }
                          {' '}
                          Subscribe & Save
                        </span>
                        )}
                        <span className="d-block fw-lighter" style={{ opacity: 0.4, fontSize: '65%' }}>
                          Renews every
                          {' '}
                          {item.subscription_frequency_in_days}
                          {' '}
                          days
                        </span>
                      </>
                      )}
                    </>
                  ) : (
                    <>
                      <span>{ numberToCurrency(item.price) }</span>
                      { subscription
                      && (
                      <span className="d-block fw-lighter" style={{ opacity: 0.4, fontSize: '65%' }}>
                        Renews every&nbsp;
                        { item.subscription_frequency_in_days == 7
                          ? 'week' : `${item.subscription_frequency_in_days} days`}
                      </span>
                      )}
                    </>
                  )
                }
            </div>

            { customerChoseLocalPickup
                && <div className="d-inline-block badge bg-secondary mt-2 tiny">Local Pickup</div>}
          </div>

          <button
            className="btn btn-link text-end"
            type="button"
            disabled={!verified}
            onClick={handleRemove}
          >
            Remove
          </button>
        </div>
      </div>
      {
        /*
         * END DESKTOP
         */
      }
      {
        /*
         * BEGIN MOBILE
         */
      }
      <div className="cart-list-item-mobile d-flex d-md-none">
        <div className="cart-list-item-image">
          <div>
            <a href={item.permalink}>
              <img src={item.featured_image_url} alt={item.featured_image_alt_text} />
            </a>
          </div>
          <div className="cart-list-item-details">
            <div className="cart-list-item-details-name h5">
              <a href={item.permalink} className="text-decoration-none d-block">
                { item.name }
              </a>
              { subscription
                && <div className="d-inline-block badge bg-secondary mt-2 tiny">Subscription</div>}
            </div>

            <div className={`cart-list-item-price h5 ${subscription ? 'border-top mt-3 pt-3' : ''}`}>
              <div>
                {
                  Boolean(item?.original_price) && Number(item.price) !== Number(item.original_price) ? (
                    <>
                      <span>{ numberToCurrency(item.price) }</span>

                      <span className="d-block fw-lighter text-decoration-line-through" style={{ opacity: 0.4, fontSize: '88%' }}>
                        { numberToCurrency(item.original_price) }
                      </span>

                      { subscription && (
                      <>
                        { customerIsMember === false
                        && (
                        <span className="d-block fw-lighter" style={{ opacity: 0.4, fontSize: '65%' }}>
                          -
                          { item.calculated_subscription_savings_percentage }
                          {' '}
                          Subscribe & Save
                        </span>
                        )}
                        <span className="d-block fw-lighter" style={{ opacity: 0.4, fontSize: '65%' }}>
                          Renews every
                          {' '}
                          {item.subscription_frequency_in_days}
                          {' '}
                          days
                        </span>
                      </>
                      )}
                    </>
                  ) : (
                    <>
                      <span>{ numberToCurrency(item.price) }</span>
                      { subscription
                      && (
                      <span className="d-block fw-lighter" style={{ opacity: 0.4, fontSize: '65%' }}>
                        Renews every&nbsp;
                        { item.subscription_frequency_in_days == 7
                          ? 'week' : `${item.subscription_frequency_in_days} days`}
                      </span>
                      )}
                    </>
                  )
                }
              </div>

              { customerChoseLocalPickup
                && <div className="d-inline-block badge bg-secondary mt-2 tiny">Local Pickup</div>}
            </div>

          </div>
        </div>
        <div className="cart-list-item-end">
          <QuantityInput
            quantity={quantity}
            handleChange={handleQuantityChange}
          />
          <button
            className="btn btn-link text-end"
            type="button"
            disabled={!verified}
            onClick={handleRemove}
          >
            Remove
          </button>
        </div>
      </div>
      {
        /*
         * END MOBILE
         */
      }

    </div>
  )
}
