import * as bootstrap from 'bootstrap'

$(() => {
  if (document.getElementById('lab-pricing-modal')) {
    const modal = new bootstrap.Modal('#lab-pricing-modal', {
      keyboard: false,
      backdrop: 'static',
    })

    modal.show()
  }
})
